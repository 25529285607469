<template>
  <div>
    <div class="row">
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >SBC Name</label>
        <input
          v-model="item.name"
          type="text"
          class="form-control"
        >
      </div>
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >Protocol</label>
        <input
          v-model="item.protocol"
          type="text"
          class="form-control"
        >
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col">
        <label
          for="exampleFormControlInput1"
          class="form-label"
        >SBC IPs</label>
        <input
          v-model="item.ip"
          type="text"
          class="form-control"
        >
      </div>
      <!-- <div class="mb-3 col">
        <label for="exampleFormControlInput1" class="form-label">Status</label>
        <input type="text" class="form-control" v-model="item.status" />
      </div> -->
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="btn btn-success"
        @click="id ? update() : create()"
      >
        Submit
      </button>
    </div>
  </div>
</template>


<script>
import axios from '@/axios'
import { notification } from 'ant-design-vue'
export default {
  data() {
    return {
      item: {
        name: null,
        ip: null,
        active: false,
      },
    }
  },
  computed: {
    id() {
      const params = this.$route.params
      return params ? params.id : null
    },
  },
  methods: {
    loadItem() {
      axios
        .get(`/authentication/sbc/${this.id}`)
        .then((res) => {
          console.log(res)
        })
        .catch(res)
    },
    create() {
      axios
        .post('/authentication/sbc', this.item)
        .then((res) => {
          notification.success({
            message: 'Сreated',
            //  description: 'You have successfully logged in!',
          })
          this.$router.push({name:'authentication-whitelist'})
        })
        .catch((err) => {
          this.$store.dispatch('error', err)
        })
    },
    update() {
      axios
        .put(`/authentication/sbc/${this.id}`, this.item)
        .then((res) => {
          notification.success({
            message: 'Updated',
            //  description: 'You have successfully logged in!',
          })
          this.$router.push('/verif-whitelist')

          console.log(res)
        })
        .catch((err) => {
          notification.error({
            message: err.response.error,
            description: [...err.response.message],
          })
          console.log(err)
        })
    },
  },
}
</script>
